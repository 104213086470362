const propertyData = {
    "url": "http://1269NEParksideDr.com/",
    "agent": {
      "name": "Aubrey Martin",
      "phone": "503.443.8889",
      "email": "aubrey@aubreymartin.com"
    },
    "full_address": {"street": "1269 NE Parkside Dr", "city": "Hillsboro", "state":"Oregon", "zipcode": "97124"},
    "display_address": "1269 NE Parkside Dr",
    "property": {
      "listing_price": "$519,888",
      "bedrooms": "4",
      "baths": "3",
      "sq_feet": "2443",
      "lot_size": "5227",
      "rmls": "20296425",
      "built_in": "2000",
      "blurb": "Beautifully maintained home in desirable Jackson School neighborhood. 4 bedroom, 3 full bath w/open floor-plan. Bedroom & full bath on main. Large light & bright kitchen w/island, gas cooking & pantry. Spacious living room w/fireplace. Formal dining room. Vaulted master suite w/walk-in closet & private bath w/soaking tub. Large bonus room. Oversized 2 car garage. Private fenced backyard w/covered patio backing to serene Jackson Woods. HOA includes pool, park, & tennis courts. Move-in Ready!",
      "ammenities": [

      ]
    },
    "image_count": 31,
    "opens": [
      {"id": "1", "item": "Friday 1/3 5:00pm-7:00pm"},
      {"id": "2", "item": "Saturday 1/4 1:00pm-3:00pm"},
      {"id": "3", "item": "Sunday 1/5 1:00pm-3:00pm"}
    ],
    "status": "",
    "google": {
        "map": {
            "lat": "45.538309",
            "lng": "-123.131938"
        },
        "maps": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2794.8028091030164!2d-122.98667388444024!3d45.53417357910178!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54951aca0240798f%3A0xcf9838287fd2ab53!2s1269%20NE%20Parkside%20Dr%2C%20Hillsboro%2C%20OR%2097124!5e0!3m2!1sen!2sus!4v1590701948550!5m2!1sen!2sus",
        "youtube":"7-TpKA8tRh8"
    }
  }

export default propertyData;

